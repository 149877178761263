import Vue from 'vue'
import VueRouter from 'vue-router'

// page
import HomePage from '@/page/home.vue'
import AboutPage from '@/page/about.vue'
import ProductPage from '@/page/product.vue'
import InformationPage from '@/page/information.vue'
import PurchasePage from '@/page/purchase.vue'
import ContactPage from '@/page/contact.vue'
import ReportPage from '@/page/report.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/product',
    component: ProductPage,
  },
  {
    path: '/information',
    component: InformationPage,
  },
  {
    path: '/purchase',
    component: PurchasePage,
  },
  {
    path: '/contact',
    component: ContactPage,
  },
  {
    path: '/report',
    component: ReportPage,
  }
]

const router = new VueRouter({
  routes
})

export default router
