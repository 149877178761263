<template>
  <el-image :src="src" :style="style" :fit="fit"></el-image>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    // 单位为rem
    width: {
      type: Number,
      default: null,
    },
    // 单位为rem
    height: {
      type: Number,
      default: null,
    },
    imageStyle: {
      type: Object,
      default: () => {
        return null
      },
    },
    fit: {
      type: String,
      default: 'cover'
    },
  },
  data() {
    return {
      style: {},
    }
  },
  watch: {
    '$root.$data._rem': {
      handler(rem) {
        if (rem === null) {
          return
        }
        this.init()
      },
      immediate: false,
      deep: true,
    }
  },
  methods: {
    init() {
      if (this.imageStyle !== null) {
        this.$set(this, 'style', this.imageStyle)
        return
      }
      let w = 'auto'
      let h = 'auto'
      const rem = this.$root.$data._rem
      if (this.width !== null) {
        w = rem * this.width + 'px'
      }
      if (this.height !== null) {
        h = rem * this.height + 'px'
      }
      this.$set(this, 'style', {
        width: w,
        height: h,
      })
    },
  },
  mounted() {
    this.init()
  },
}
</script>