<template>
  <div :class="`header-inner ${isMobile ? 'inner-mobile' : ''}`">
    <CImage :src="logo" :height="0.5" />
    <SizeBox :width="0.4" :height="0.4" />
    <div class="menu-list" @click="onClickMenu">
      <div class="menu-item" v-for="(menu, i) in $root.$data._menuList" :key="'menu_' + i" :data-path="menu.path">
        <div class="text-en">{{menu.en}}</div>
        <SizeBox :height="0.1" />
        <div class="text-zh">{{menu.zh}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo.png'
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo,
    }
  },
  methods: {
    onClickMenu(event) {
      let emitEvent = false
      let target = event.target
      while(true) {
        if (target === null) {
          break
        }
        const className = target.className
        if (className === 'menu-item') {
          emitEvent = true
          break
        }
        if (className === 'menu-list') {
          break
        }
        target = target.parentNode
      }
      if (emitEvent) {
        this.$emit('clickMenu')
        const path = target.dataset.path
        if (window.location.hash.replaceAll('#', '') === path) {
          return
        }
        this.$router.replace({
          path: path
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.header-inner {
  display: flex;
  align-items: center;
  .menu-list {
    display: flex;
    align-items: center;
    .menu-item {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0.3rem 0 0;
      cursor: pointer;
      &:last-child {
        margin: 0 !important;
      }
      .text-en, .text-zh {
        font-size: 0.16rem;
        color: $fontC;
      }
    }
  }
}
.inner-mobile {
  flex-direction: column !important;
  padding: 0 0.2rem;
  .menu-list {
    flex-direction: column !important;
    .menu-item {
      margin: 0 0 0.3rem 0 !important;
      &:last-child {
        margin: 0 !important;
      }
      .text-en, .text-zh {
        font-size: 0.24rem;
      }
    }
  }
}
</style>