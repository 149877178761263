<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    onResize() {
      let isMobile = false
      let rem = 0
      const documentElement = document.documentElement
      const screenWidth = documentElement.clientWidth

      // 尺寸：width<=750-移动设备 750<width<=1280-笔记本电脑 width>1280-台式机
      if (screenWidth <= 750) {
        isMobile = true
        rem = 50
      } else if (screenWidth <= 1280) {
        rem = 80
      } else {
        rem = 100
      }
      documentElement.style.fontSize = rem + 'px'

      this.$set(this.$root.$data, '_isMobile', isMobile)
      this.$set(this.$root.$data, '_rem', rem)
    },
  },
  mounted() {
    window.onresize = this.onResize;
    this.onResize()
  },
}
</script>

<style lang="scss">
@import '@/assets/style/common.scss';
#app {
  display: flex;
  flex-direction: column;
  .page-container {
    flex: 1 1;
  }
}
</style>
