<template>
  <!-- 移动端 -->
  <div class="header-container header-mobile" v-if="$root.$data._isMobile">
    <i class="el-icon-menu" @click="showMenu = true"></i>
    <el-drawer custom-class="c-drawer" :visible.sync="showMenu" direction="ltr" :append-to-body="true" :modal-append-to-body="true" :show-close="false" @close="showMenu = false">
      <HeaderInner :isMobile="true" @clickMenu="showMenu = false" />
    </el-drawer>
  </div>
  <!-- PC -->
  <div class="header-container" v-else>
    <HeaderInner @clickMenu="showMenu = false" />
  </div>
</template>

<script>
import HeaderInner from '@/components/view/HeaderInner.vue'
export default {
  components: {
    HeaderInner,
  },
  data() {
    return {
      showMenu: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.header-container {
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  width: max-content;
  background-color: white;
  padding: 0.12rem;
  border-radius: 0.12rem;
  box-shadow: 0 0.02rem 0.12rem 0 rgba(0,0,0,.1);
}
.header-mobile {
  position: fixed;
  top: 0.4rem !important;
  left: 0.4rem !important;
  transform: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  .el-icon-menu {
    font-size: 0.4rem;
  }
}
</style>