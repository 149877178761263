<template>
  <div class="breadcrumb-container">
    <div class="crumb-item" v-for="(item, i) in stack" :key="'crumb_' + i">
      <div :class="`text ${item.canTo ? 'link' : ''}`" @click="onClickCrumb(item)">{{item.zh}}</div>
      <div class="arrow" v-if="(i < stack.length - 1)">{{'>>'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stack: [],
    }
  },
  watch: {
    '$route.path': {
      handler(path) {
        this.init(path)
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    init(path) {
      let currentRoute = null
      let stack = []
      if (path !== null && path !== '/') {
        const menuList = this.$root.$data._menuList
        var i = 0
        while(true) {
          if (i === menuList.length) {
            break
          }
          const menu = menuList[i]
          if (menu.path === path) {
            stack.push({
              path: '/',
              en: 'Home Page',
              zh: '网站首页',
              canTo: true,
            })
            currentRoute = {
              ...menu,
              canTo: false,
            }
            stack.push(currentRoute)
            break
          }
          i++
        }
      }
      this.$set(this, 'stack', stack)
      this.$emit('init', currentRoute)
    },
    onClickCrumb(item) {
      if (!item.canTo) {
        return
      }
      this.$router.replace({
        path: item.path,
      })
    }
  },
  mounted() {
    const path = window.location.hash.replaceAll('#', '')
    this.init(path)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.breadcrumb-container {
  display: flex;
  align-items: center;
  width: calc(100% - 0.8rem);
  font-size: 0.24rem;
  color: $fontC;
  margin: 0 0.4rem;
  .crumb-item {
    display: flex;
    align-items: center;
    .link {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .arrow {
      padding: 0 0.1rem;
    }
  }
}
</style>