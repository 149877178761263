import Vue from 'vue'
import App from './App.vue'
import router from './router'

/**
 * 组件库：ElementUI
 * https://element.eleme.cn/#/zh-CN
 */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

/**
 * 公共组件
 */
import SizeBox from '@/components/SizeBox.vue'
import Image from '@/components/Image.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'

Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.component('SizeBox', SizeBox)
Vue.component('CImage', Image)
Vue.component('BreadCrumb', BreadCrumb)

const menuList = [
  {
    path: '/',
    en: 'Home Page',
    zh: '网站首页',
  },
  {
    path: '/about',
    en: 'About Us',
    zh: '关于我们',
  },
  {
    path: '/product',
    en: 'Product Center',
    zh: '产品中心',
  },
  {
    path: '/information',
    en: 'Information Center',
    zh: '资讯中心',
  },
  {
    path: '/purchase',
    en: 'Purchase Channels',
    zh: '购买渠道',
  },
  {
    path: '/contact',
    en: 'Contact Us',
    zh: '联系我们',
  },
  {
    path: '/report',
    en: 'Quality Inspection Report',
    zh: '质检报告',
  },
]

new Vue({
  data: () => {
    return {
      _rem: null,
      _isMobile: null,
      _menuList: menuList,
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
