<template>
  <div class="page-container">
    <CImage :src="pageBg" :imageStyle="{width: '100%'}" />
    <div class="content-container">
      <BreadCrumb />
    </div>
  </div>
</template>

<script>
import pageBg from '@/assets/images/page_bg.png'
export default {
  data() {
    return {
      pageBg,
    }
  },
}
</script>

<style lang="sass" scoped>
</style>