<template>
  <div class="footer-container">
    <SizeBox :height="0.6" />
    <div :class="`footer-inner ${$root.$data._isMobile ? 'inner-mobile' : ''}`">
      <div class="navigation">
        <div class="navigation-item" v-for="(menu, i) in $root.$data._menuList" :key="'navigation_' + i" @click="onClickNavigation(menu.path)">{{menu.zh}}</div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="contact">
        <div class="row">
          <i class="icon el-icon-phone"></i>
          <SizeBox :width="0.2" />
          <div class="text">123456789</div>
        </div>
        <div class="row">
          <i class="icon el-icon-s-promotion"></i>
          <SizeBox :width="0.2" />
          <div class="text">974107586@qq.com</div>
        </div>
        <div class="row">
          <i class="icon el-icon-location"></i>
          <SizeBox :width="0.2" />
          <div class="text">广州市天河区金豪商务大厦</div>
        </div>
      </div>
    </div>
    <SizeBox :height="0.4" />
    <div class="copyright">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="color: white;text-decoration: none;">粤ICP备2024200996号</a>
    </div>
    <div class="copyright">​版权所有：广州科启信息科技有限公司</div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickNavigation(path) {
      if (window.location.hash.replaceAll('#', '') === path) {
        return
      }
      this.$router.replace({
        path: path
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #0733B6;
  .footer-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    .navigation {
      display: flex;
      flex-wrap: wrap;
      max-width: calc(0.6rem + 2.4rem);
      .navigation-item {
        font-size: 0.2rem;
        margin: 0 0.3rem 0.2rem 0;
        cursor: pointer;
        &:nth-child(3n+3) {
          margin: 0 !important;
        }
      }
    }
    .el-divider {
      margin: 0 0.4rem !important;
    }
    .contact {
      .row {
        display: flex;
        align-items: flex-start;
        max-width: calc(0.6rem + 2.4rem);
        margin: 0 0 0.2rem 0;
        .icon {
          font-size: 0.28rem;
        }
        .text {
          font-size: 0.2rem;
          word-break: break-all;
        }
      }
    }
  }
  .copyright {
    font-size: 0.2rem;
    margin-bottom: 0.2rem;
  }
}
</style>