<template>
  <div class="sizebox-container"></div>
</template>

<script>
export default {
  props: {
    // 单位为rem
    width: {
      type: Number,
      default: 0,
    },
    // 单位为rem
    height: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    '$root.$data._rem': {
      handler(rem) {
        if (rem === null) {
          return
        }
        this.init()
      },
      immediate: false,
      deep: true,
    }
  },
  methods: {
    init() {
      const rem = this.$root.$data._rem
      this.$el.style.width = this.width * rem + 'px'
      this.$el.style.height = this.height * rem + 'px'
    },
  },
  mounted() {
    this.init()
  },
}
</script>