<template>
  <div class="page-container">
    <div class="bg-container">
      <CImage :src="pageBg" :imageStyle="{width: '100%'}" />
      <div class="title" v-if="currentRoute !== null">
        <div class="text">{{currentRoute.en}}</div>
        <SizeBox :height="0.1" />
        <div class="text">{{currentRoute.zh}}</div>
      </div>
    </div>
    <div class="content-container">
      <BreadCrumb @init="onBreadCrumbInit" />
    </div>
  </div>
</template>

<script>
import pageBg from '@/assets/images/page_bg.png'
export default {
  data() {
    return {
      pageBg,
      currentRoute: null
    }
  },
  methods: {
    onBreadCrumbInit(route) {
      if (route === null) {
        return
      }
      this.$set(this, 'currentRoute', route)
    }
  },
}
</script>

<style lang="sass" scoped>
</style>